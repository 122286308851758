<script setup>
import Layout from "../../../layouts/main.vue";
import mushroom from "cem-probe-api";
import { onMounted, reactive, ref, watch } from "vue";
import MethodService from "../../../service/MethodService";
import toastr from "toastr";
import { useRoute, useRouter } from "vue-router";
const route = useRoute();
import { useI18n } from "vue-i18n";
const { t } = useI18n();
// pinia store
import { changeEdit } from "../../../stores/changeEdit";
const storeChangeEdit = changeEdit();
import { ListService } from "../../../stores/ListService";
const storeListService = ListService();
import { listMenu } from "@/stores/ListMenu";
const storeListMenu = listMenu();
import { listIcons } from "@/stores/ListIcons";
const storeListIcons = listIcons();
import { generalKhachHang } from "../../../stores/generalKhachHang";
const storeGeneraKhachHang = generalKhachHang();
import { isHaveDataDashboard } from "../../../stores/HaveDataDashboard";
const storeIsHaveDataDashboard = isHaveDataDashboard();
// import { timePicker } from "../../../stores/timePicker";
// const storeTimePicker = timePicker();
import { datePicker } from "../../../stores/datePicker";
const storeDatePicker = datePicker();
import { ListDashboardPopup } from "@/stores/ListDashboardPopup";
const listDashboardPopup = ListDashboardPopup();
import DashboardCard from "../../../components/DashboardCardComponent.vue";
import SortCardDashboard from "../../../components/SortCardDashboard.vue";
import SearchInfinite from "../../../components/SearchInfinite.vue";
import Loading from "../../../components/loading-hello.vue";
// import { Timer } from "@element-plus/icons-vue";
import Swal from "sweetalert2";
import { abortAPI } from "@/stores/abortAPI";

const items = reactive({ value: [] });
const indexCard = ref(0);
const titleCard = ref("");
const idNewCard = ref("");
const showSortCard = ref(false);
const loadingData = ref(true);
const idCustomerNo1 = ref("");
// const startTime = ref();
// const endTime = ref();
const loadingBtn = ref(false);
const isClickDownload = ref(true); // nút download đang hiển thị
const isBtnLoading = ref(false); // nút Đang download
const idRequestDownload = ref("");
const textSearch = reactive({ text: "" });
const dataSearch = ref([]);
const isSearch = ref(false);
const loadingState = ref(""); // hiển thị loading khi gọi api tìm kiếm
const inputSearch = ref(null);
const router = useRouter();
const storeAbortAPI = abortAPI();
const abortController = new AbortController();

// lấy về danh sách card theo màn khách hàng
const getAllCard = async () => {
  loadingData.value = true;
  items.value = [];
  try {
    const response = await mushroom.card.listAsync({
      filters: ["dashboard=timespan"],
    });
    if (response.result?.length > 0) {
      indexCard.value = response.result.length;
      items.value = [];
      response.result.forEach((item) => items.value.push(item));
      storeIsHaveDataDashboard.haveData();
    } else storeIsHaveDataDashboard.noData();
  } catch (e) {
    console.error("Có lỗi: %o", e);
    MethodService.showError(e.code);
  } finally {
    loadingData.value = false;
  }
};

const addCard = () => {
  const addDomCard = {
    id: Math.random() + "",
  };
  Swal.fire({
    title: t("t_input_card_title"),
    input: "text",
    inputAttributes: {
      autocapitalize: "off",
    },
    confirmButtonText: t("t-add"),
    icon: "info",
    showLoaderOnConfirm: true,
    cancelButtonText: t("t-cancel"),
    showCancelButton: true,
    allowOutsideClick: false,
    preConfirm: (title) => {
      if (!title) {
        Swal.showValidationMessage(t("t_please_enter_card_title"));
      } else {
        titleCard.value = title;
        indexCard.value = indexCard.value + 1;
        addDomCard.title = title;
      }
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      await saveCard(titleCard.value, indexCard.value);
      addDomCard.id = idNewCard.value;
      await getAllCard();
    }
  });
};

const saveCard = async (titleCard, indexCard) => {
  const card_object = {
    dashboard: "timespan",
    title: titleCard,
    index: indexCard,
    header: {
      is_hidden: false,
    },
  };
  try {
    const newId = await mushroom.card.createAsync(card_object);
    if (newId) toastr.success(t("toastr_add_card_success"));
    idNewCard.value = newId.result;
  } catch (e) {
    MethodService.showError(e.code);
  }
};

const reloadGridDashboard = () => {
  loadingData.value = true;
  setTimeout(() => {
    getAllCard();
  }, 1000);
};
const dashboardType = ref("timespan");
const showSortCardTable = () => {
  showSortCard.value = !showSortCard.value;
};
const cancelShowSortCard = () => {
  showSortCard.value = false;
};

const reloadingData = () => {
  loadingData.value = true;
};
const reRenderCard = () => {
  //render lại card khi xoá 1 card-items
  KeyReRenderCard.value += 1;
};
const reRenderCardAfterOtionCard = async () => {
  await getAllCard();
};
const KeyReRenderCard = ref(0);
const unloadingData = () => {
  setTimeout(() => {
    loadingData.value = false;
  }, 2000);
};

const setValueCustomer = (data, query) => {
  const label = data.find((item) => item.key === query)?.label;
  if (label) {
    textSearch.text = label;
    idCustomerNo1.value = query;
  }
  window.localStorage.setItem("customer", query);
};

const getAllCustomers = async (type) => {
  let dataBody = {
    probe_id: localStorage.pid,
    search_key: "",
  };
  if (type == "no-search") {
    loadingState.value = "";
    isSearch.value = false;
  } else {
    loadingState.value = "loading";
    isSearch.value = true;
    dataBody.search_key = textSearch.text;
  }
  try {
    const response = await mushroom.probe_data.getCustomersAsync({
      body: dataBody,
      settings: {
        abortController,
      },
    });
    if (type == "no-search") {
      if (!route.query.mac) {
        setValueCustomer(response.result, response.result[0].key);
        var search = location.search.substring(1);

        let queryForPush = Object.fromEntries(new URLSearchParams(search));
        queryForPush.customer = response.result[0].key;

        router.replace({
          path: window.location.pathname,
          query: queryForPush,
        });
      }
    } else {
      dataSearch.value = [];
      response.result.forEach((item) => {
        dataSearch.value.push({
          key: item.key,
          label: item.label,
        });
      });
    }
  } catch (e) {
    MethodService.showError(e.code);
  } finally {
    loadingState.value = "";
  }
};
const clearValueSearch = () => {
  loadingState.value = "";
  isSearch.value = false;
  dataSearch.value = [];
};
const keySearch = (data) => {
  inputSearch.value.focus();
  textSearch.text = data.label;
  idCustomerNo1.value = data.key;
  isSearch.value = false;

  var search = location.search.substring(1);

  let queryForPush = Object.fromEntries(new URLSearchParams(search));
  queryForPush.customer = data.key;

  router.replace({
    path: window.location.pathname,
    query: queryForPush,
  });

  window.localStorage.setItem("customer", data.key);
  getAllCard();
};

// const setTimeToStore = (startTime, endTime) => {
//   storeTimePicker.setTimePicker([startTime, endTime]);
// };

const getDataWhenChangeCustomer = (keySearch) => {
  if (!keySearch) return;
  loadingState.value = "loading";
  getAllCustomers("search");
};

// const changeTime = () => {
//   setTimeout(() => {
//     if (startTime.value && endTime.value) {
//       if (
//         startTime.value < 0 ||
//         endTime.value < 0 ||
//         startTime.value > 24 ||
//         endTime.value > 24
//       ) {
//         toastr.warning("Thời gian từ 0 giờ đến 24 giờ");
//         return;
//       } else if (Number(startTime.value) > Number(endTime.value)) {
//         toastr.warning(
//           "Thời gian bắt đầu phải nhỏ hơn hoặc bằng thời gian kết thúc"
//         );
//         return;
//       } else {
//         setTimeToStore(startTime.value, endTime.value);
//       }
//     } else if (!startTime.value && !endTime.value) setTimeToStore(null, null);
//   }, 1000);
// };

const downloadCard = async () => {
  loadingBtn.value = true;
  isClickDownload.value = false;
  isBtnLoading.value = true;
  storeIsHaveDataDashboard.downloadData();
  const search = location.search.substring(1);
  const data = search
    ? JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      )
    : "";

  // 1. gửi lên để có id request
  const request_download_dashboard_object = {
    probe_id: localStorage.pid,
    type: "timespan",
    customer: idCustomerNo1.value,
    start_date: storeDatePicker.showDatePicker[0],
    stop_date: storeDatePicker.showDatePicker[1],
    data: search ? JSON.stringify(data) : undefined,
  };
  try {
    const newId = await mushroom.request_download_dashboard.createAsync(
      request_download_dashboard_object
    );
    if (newId.result) {
      idRequestDownload.value = newId.result;
    }
    // 2. nếu request thành công, check trạng thái 5s 1 lần
    if (idRequestDownload.value.length > 0) {
      window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
    } else clearTimeout(window.checkStatusDownloadProbe);
  } catch (error) {
    console.error("Có lỗi: %o", error);
  }
};

const checkStatus = async () => {
  console.log("cứ 5s gọi log 1 lần, id: ", idRequestDownload.value);
  let response;
  try {
    response = await mushroom.request_download_dashboard.findByIdAsync(
      {
        id: idRequestDownload.value,
      },
      { enabledCache: false }
    );
  } catch (e) {
    console.error("Có lỗi: %o", e);
    loadingBtn.value = false;
    isClickDownload.value = true;
    isBtnLoading.value = false;
    window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
    return;
  }

  if (response.result.status == "error") {
    loadingBtn.value = false;
    isClickDownload.value = true;
    isBtnLoading.value = false;
    window.toastr.error(response.result.note);
    clearTimeout(window.checkStatusDownloadProbe);
    storeIsHaveDataDashboard.stopDownloadData();
    return;
  }

  if (response.result.status == "completed") {
    await MethodService.downloadFileDashboard(
      idRequestDownload.value,
      "dashboard_timespan.xlsx"
    );
    loadingBtn.value = false;
    isClickDownload.value = true;
    isBtnLoading.value = false;
    clearTimeout(window.checkStatusDownloadProbe);
    storeIsHaveDataDashboard.stopDownloadData();
    return;
  }
  window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
};

const hashUrl = () => {
  var search = location.search.substring(1);
  console.log("storeDatePicker.showDatePicker", storeDatePicker.showDatePicker);
  let queryForPush = Object.fromEntries(new URLSearchParams(search));
  queryForPush.start_date = MethodService.formatDateYYYYMMDD(
    storeDatePicker.showDatePicker[0]
  );
  queryForPush.stop_date = MethodService.formatDateYYYYMMDD(
    storeDatePicker.showDatePicker[1]
  );

  router.push({
    path: window.location.pathname,
    query: queryForPush,
  });
};

watch(
  () => storeChangeEdit.showEdit,
  async () => {
    await getAllCard();
  }
);
watch(
  () => storeDatePicker.showDatePicker,
  () => hashUrl()
);

onMounted(async () => {
  storeAbortAPI.setAbort(abortController);
  if (route.query?.start_date) {
    localStorage.setItem("dateSelect", [
      route.query.start_date,
      route.query.stop_date,
    ]);
    storeDatePicker.setDatePicker([
      route.query.start_date,
      route.query.stop_date,
    ]);
  } else {
    hashUrl();
  }

  if (route.query.customer) {
    textSearch.text = route.query.customer;
    idCustomerNo1.value = route.query.customer;
    window.localStorage.setItem("customer", route.query.customer);
  } else if (route.query.mac) {
    textSearch.text = route.query.mac;
    idCustomerNo1.value = route.query.mac;
    window.localStorage.setItem("customer", route.query.mac);
  } else await getAllCustomers("no-search");
  await storeGeneraKhachHang.getSummary();
  await storeListService.getAllListService();
  await listDashboardPopup.getAllListDashboardPopup();
  await storeListMenu.getListMenuPhanCap();
  await storeListIcons.getListIcons();
  await getAllCard();
});
</script>

<template>
  <Layout>
    <div class="container-fluid dashboard-all">
      <loading v-if="loadingData"></loading>
      <div class="group-search">
        <b-row>
          <b-col md="3" class="group-search__input">
            <el-form
              :model="textSearch"
              style="display: flex; width: 288px"
              @submit.prevent
            >
              <el-input
                v-model="textSearch.text"
                :placeholder="`${$t('t-search')}`"
                clearable
                @input="getDataWhenChangeCustomer(textSearch.text)"
                @clear="clearValueSearch"
                ref="inputSearch"
              />
            </el-form>
            <search-infinite
              :loadingState="loadingState"
              :dataSearch="dataSearch"
              :isSearch="isSearch"
              @keySearch="keySearch"
            ></search-infinite>
          </b-col>
          <!-- -- ẩn theo yêu cầu của khách hàng --
          <b-col md="6">
            <div class="d-flex mb-2 align-items-center">
              <div class="" style="width: 135px; margin-right: 10px">
                <el-input
                  v-model="startTime"
                  type="number"
                  min="0"
                  max="24"
                  :placeholder="`${$t('t-start-time')}`"
                  :prefix-icon="Timer"
                  @input="changeTime"
                  class="inputTime"
                >
                  <template #append>h</template>
</el-input>
</div>
<i class="ri-subtract-line" style="margin-right: 10px; color: #999ca3"></i>
<div class="" style="width: 135px">
  <el-input v-model="endTime" type="number" min="0" max="24" :placeholder="`${$t('t-end-time')}`" :prefix-icon="Timer"
    @input="changeTime" class="inputTime">
    <template #append>h</template>
  </el-input>
</div>
</div>
</b-col> -->
        </b-row>
      </div>
      <div
        class="text-center no-card"
        v-if="items.value.length === 0 && !loadingData"
      >
        <i
          style="
            font-size: 40px;
            opacity: 0.5;
            color: #e0ba00;
            margin-right: 10px;
          "
          class="ri-bank-card-line"
        ></i>
        <span>{{ $t("t-no-card") }}</span>
      </div>
      <div
        v-show="items.value.length > 0"
        v-for="item in items.value"
        :key="item.id"
      >
        <dashboard-card
          :cardId="item.id"
          :cardTitle="item.title"
          :cardIndex="item.index"
          :headerOptions="item?.header"
          @reloadGridDashboard="reloadGridDashboard"
          @reloadingData="reloadingData"
          @unloadingData="unloadingData"
          @showSortCardTable="showSortCardTable"
          @reRenderCardAfterOtionCard="reRenderCardAfterOtionCard"
          @reRenderCard="reRenderCard"
          :key="KeyReRenderCard"
        ></dashboard-card>
      </div>

      <div
        class="add-card d-flex justify-content-end"
        v-if="storeChangeEdit.showEdit === true"
      >
        <el-button
          @click="addCard"
          type="button"
          style="height: 40px"
          class="btn btn-secondary btn-border"
        >
          {{ $t("t-add-card") }}
        </el-button>
      </div>
      <div
        class="download-card"
        v-if="storeChangeEdit.showEdit === false && items.value.length > 0"
      >
        <div>
          <el-button
            v-if="isClickDownload"
            @click="downloadCard"
            type="button"
            style="height: 40px"
            class="btn btn-secondary btn-border button-dashboard hide"
          >
            {{ $t("t-download") }}
          </el-button>
          <el-button
            v-if="isBtnLoading"
            type="button"
            style="height: 40px"
            class="btn btn-secondary btn-border button-dashboard hide"
            :loading="loadingBtn"
          >
            {{ $t("t_loading") }}...
          </el-button>
        </div>
      </div>
      <div v-if="showSortCard">
        <sort-card-dashboard
          :dashboardType="dashboardType"
          @cancelShowSortCard="cancelShowSortCard"
          @reloadGridDashboard="reloadGridDashboard"
        ></sort-card-dashboard>
      </div>
    </div>
  </Layout>
</template>
<style scoped>
:deep(.inputTime .el-input__inner) {
  text-align: end !important;
}
</style>
<link type="scss" src="./dashboard.scss" />
